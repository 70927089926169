import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Panel Manufacturing Plant' },
    { img: data.i2, caption: 'Panel Manufacturing Plant' },
    { img: data.i3, caption: 'Metal Stud Wall Panel' },
    { img: data.i4, caption: 'Panelization Plant' },
    { img: data.i5, caption: 'Wall Panels on Dropped Trailer' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: Pre-Panelized Load-Bearing Metal Stud Walls" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>Pre-Panelized Load-Bearing Metal Stud Walls</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>
              The Infinity wall panels are pre-fabricated off-site in a panelization
              plant utilizing welded connections and very tight tolerances to
              achieve the highest quality available. We have expanded and improved
              our metal stud wall panel manufacturing capabilities on a continuing
              basis to ensure prompt, on-time deliveries with state-of-the-art
              quality control.
            </p>
            <p>
              <Link className="link-next" to="/system-overview/installing-wall-panels/">
                Next Page: Installing Wall Panels
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
         query {
             i1: file(relativePath: { eq: "system-overview/metal-stud-walls/gallery/01-Panel080_(40684).png" }) { 
                 ...SystemOverviewCarouselImageFragment },
             i2: file(relativePath: { eq: "system-overview/metal-stud-walls/gallery/02-Panel050_(40681).png" }) { 
                 ...SystemOverviewCarouselImageFragment },
             i3: file(relativePath: { eq: "system-overview/metal-stud-walls/gallery/03-Infin100_(not_in_software).jpg" }) { 
                 ...SystemOverviewCarouselImageFragment },
             i4: file(relativePath: { eq: "system-overview/metal-stud-walls/gallery/04-Panel020_(34248).png" }) { 
                 ...SystemOverviewCarouselImageFragment },
             i5: file(relativePath: { eq: "system-overview/metal-stud-walls/gallery/05-WallP190_(33962).png" }) { 
                 ...SystemOverviewCarouselImageFragment },
         }
       `
